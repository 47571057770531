/*
 * @Description: 
 * @Date: 2023-10-31 21:22:42
 * @LastEditTime: 2023-11-01 01:17:30
 * @FilePath: \pre-saas平台开发\src\router\homepage.js
 */
const Layout = () => import('@/components/layout/zy_sass_layout.vue')
const HomepageAdv =() => import('@/views/homeadv/zy_saas_homeadv.vue')
const Notice = () => import('@/views/notice/zy_saas_notice.vue')
const Account = () => import('@/views/account/zy_saas_account.vue')
const routes =  { 
  path:'/homepage',
  name:'homepage',
  meta:{
    title:'首页广告位',
    requireAuth:true
  },
  component:Layout,
  children:[
    {
      path:'homepageadv',
      name:'homepageadv',
      meta:{
        title:'首页广告位',
        requireAuth:true
      },
      component:HomepageAdv
    },
    {
      path:'notice',
      name:'notice',
      meta:{
        title:'通知管理',
        requireAuth:true
      },
      component:Notice
    },
    {
      path:'account',
      name:'account',
      meta:{
        title:'账号管理',
        requireAuth:true
      },
      component:Account
    }
  ]
}

export default routes