/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: fxj
 * @Date: 2023-06-12 14:36:40
 * @LastEditors: songtutu 1172120378@qq.com
 * @LastEditTime: 2023-07-12 09:43:12
 */
import  utils  from '@/utils/util'
import { toRaw } from 'vue'
import {
  clearThirdMenuList, 
  updateThirdMenuList, //更新/清除三级菜单
  updateToken, 
  updateButtonList,
  updateRole, //更新角色相关
  emptyToken,//更新/清除token
  // updateSmscode, 
  // updateMessage, setMessage,
  toggleMenu,//切换
  updateCurrentPath,//面包屑数组更新
  increateTag,//添加路由记录  
  // initTag,//更新菜单
  increateField,//表格头部切换
  updateField,//更新当前菜单选中
  setOpenSubmenu,//打开菜单
  clearAllTags,//清除浏览记录
  removeTag ,//清除浏览记录
  clearOtherTags,//清除其余浏览记录
  setCurrentPageName, //设置当前页名称
  // updateCurrentModules, //更新当前模块

} from './mutations-type'

import { createStore } from 'vuex'
import user from './user'
import system from './system'

export default createStore({
  state: {
    token: null,


    menuParentList:[],
    menuParentListIsRoute:[],

    //用户信息
    userinfo:{
        id:null,
        name:null, //登录人
        avatar:null,
        userName:null //账号
    },

    //浏览记录
    tagList: [],
    
    //当前模块
    // currentModules:null,
    currentPageName: '', //面包屑当前名称
    currentPath: [],  // 面包屑数组   
    openedSubmenuArr: [],  // 要展开的菜单数组 

    menuPadding: 200,
    tagField: [],
    
    //三级列表
    thirdMenuList: [],
    //权限按钮
    buttonlist:[],
    
    //页面是否忽略超时提示
    ignoreTag: false
  },
  getters: {
  },
  mutations: {
  //————————————————————用户信息————————————————————

    /**
     * 用户信息更新
     * @param {Object} state vuex数据 
     * @param {Object} data  api数据
     */
    [updateToken](state, data) {
      const { token, user } = { ...data };
      state.token = token
      utils.CryptoJSEncrypt('SAASTOKEN',token)
      utils.CryptoJSEncrypt('SAASPARTNERID',user.partnerId)
      utils.CryptoJSEncrypt('SAASUSER',user.username)
      Object.assign(state.userinfo, user)
      // powers.unshift({
      //     href: "confighome",
      //     icon: 'ios-home',
      //     isMenu: 1,
      //     name: "首页",
      //     parentId: null
      // })
      // state.menulist = powers  //  子级菜单
      //按钮
      // buttonList.forEach(el => {
      //     state.buttonlist.push(el.powerId)
      // })
  },
  
  // [updateSmscode](state, data) {
  //     state.userinfo.smscode = data
  // },
  // [updateMessage](state) {
  //     state.userinfo.message > 0 ? state.userinfo.message-- : state.userinfo.message = 0
  // },   
  // [setMessage](state, data) {
  //     state.userinfo.message = data
  // }, 

  //清空用户信息(token,id)
  [emptyToken](state) {
      state.token = null
      state.userinfo.id = null
      localStorage.clear()

  },

  //更新用户角色信息
  [updateRole](state,roleMsg){
     let { roleList,currentRoleId} =roleMsg
     if(roleList){ state.role.roleList = roleList}
     if(currentRoleId){ 
         state.role.currentRoleId = currentRoleId
         utils.CryptoJSEncrypt('ZYROLEID',currentRoleId)
     }
  },
  
  //————————————————————面包屑————————————————————

  //面包屑数组更新
  [updateCurrentPath](state, data) {
      state.currentPath = data;
      localStorage.setItem('SAASCURRENTPATH',JSON.stringify(data))
  },
  
  
  //设置当前页名称
  [setCurrentPageName](state, name) {
      state.currentPageName = name;
  },  


  //————————————————表格——————————————

  //表格头部切换
  [increateField](state, fieldObj) {
      state.tagField.push(fieldObj);
      localStorage.tagField = JSON.stringify(state.tagField);
  },

  
 

  // [initTag](state) {
  //  let defaultTag = [{
  //      title: '首页',
  //      path: '/home',
  //      name: 'Home'
  //  }];
  //  state.tagList = localStorage.tagList ? JSON.parse(localStorage.tagList) : defaultTag
  // },

  //设置当前页名称
    
  
  
  //————————————————浏览记录——————————————

  /**
   * 添加浏览记录 
   * @param {Object} state vuex数据 
   * @param {Object} tagObj  当前路由信息
   */
  [increateTag](state, tagObj) {
      state.tagList.push(tagObj);
      localStorage.SAASTAGLIST = JSON.stringify(state.tagList);
  },

  /**
   * 清除指定浏览记录
   * @param {Object} state vuex数据 
   * @param {Object} name  需要清除当前路由名称
   */
  [removeTag] (state, name) {
      state.tagList.map((item, index) => {
          if (item.name === name) {
              state.tagList.splice(index, 1);
          }
      });
      localStorage.SAASTAGLIST = JSON.stringify(state.tagList);
  },
  

  
  /**
   * 清除所有浏览记录
   * 清除所有的浏览记录，但保存当前模块的首页以及控制面板
   * @param {Object} state vuex数据 
   */
  [clearAllTags](state, vm) {
      //清空头部浏览记录
      state.tagList.splice(1);
      localStorage.SAASTAGLIST = JSON.stringify(state.tagList);
  },
  
  /**
   * 保留指定浏览记录
   * 仅保存指定的路由，其余清除
   * @param {Object} state vuex数据
   * @param {Object} vm 保留的路由
   */
  [clearOtherTags] (state, vm) {
      let currentName = vm.$route.name;
      let currentIndex = 0;
      state.tagList.forEach((item, index) => {
          if (item.name === currentName) {
              currentIndex = index;
          }
      });
      if (currentIndex === 0) {
          state.tagList.splice(1);
      } else {
          state.tagList.splice(currentIndex + 1);
          state.tagList.splice(1, currentIndex - 1);
      }
      localStorage.SAASTAGLIST = JSON.stringify(state.tagList);
  },
  


  //————————————————————主菜单————————————————
  
  /**
   * 控制菜单显示/隐藏
   * 当为treu时，打开菜单，否则关闭菜单
   * @param {Object} state 
   * @param {Object} modulesObj  模块名称
   */
  // [updateCurrentModules](state, modulesObj) {
  //    if(modulesObj){
  //      state.currentModules = modulesObj
  //    }
  // },

  /**
   * 控制菜单显示/隐藏
   * 当为treu时，打开菜单，否则关闭菜单
   * @param {Object} state 
   * @param {Boolean} flag  //主菜单显示/隐藏标志
   */
  [toggleMenu](state, flag) {
    flag ? state.menuPadding = 200 : state.menuPadding = 0
  },

  /**
   * 主菜单选中
   * @param {Object} state 
   * @param {String} name 路由名称
   */
  [setOpenSubmenu](state, name) {
    state.openedSubmenuArr = name
  },
  

  /**
   * 更新主菜单选中（高亮）
   * @param {Object} state 
   * @param {Object} fieldObj 需要更新选中的主菜单
   */
  [updateField](state, fieldObj) {
     state.tagField.splice(fieldObj.index, 1);
     state.tagField.push({ field: fieldObj.field, name: fieldObj.name })
     localStorage.tagField = JSON.stringify(state.tagField);
  },

  
  /**
   * 更新三级菜单
   * @param {Object} state 
   * @param {Array} thirdMenuList 菜单列表
   */
  [updateThirdMenuList](state, thirdMenuList) {

     //需要更新的值
     let _inMenuList = []
     _inMenuList = thirdMenuList

     let _storeThirdMenuList = state.thirdMenuList 
     const _localMenuList = JSON.parse(localStorage.getItem('SAASTHIRDMENULIST'))
  
     //原有菜单列表
     let _agoThirdMenuList = _storeThirdMenuList.length ? _storeThirdMenuList : (_localMenuList ? _localMenuList : [])

   
     let _newThirdMenuList = []

     
     
     
     //更新菜单
     state.thirdMenuList = _inMenuList

     localStorage.setItem('SAASTHIRDMENULIST', JSON.stringify(_inMenuList))
  },

  //清空三级菜单
  [clearThirdMenuList](state) {
     state.thirdMenuList = {}
  },
  
  //更新按钮列表
  [updateButtonList](state,buttonList){
     //状态库的数据
     let _storeButtonlist = toRaw(state.buttonlist)
     //获取缓存权限
     let _localStorageButtonlist = localStorage.getItem('SAASBUTTONLIST')
     //存储的数据
     let _newButtonlist = _storeButtonlist.length ? _storeButtonlist : (_localStorageButtonlist ? _localStorageButtonlist : [] )
     let _buttonlistStore = [...new Set([..._newButtonlist,...buttonList].map(t => JSON.stringify(t)))].map(s => JSON.parse(s))
     state.buttonlist = _buttonlistStore
     localStorage.setItem('SAASBUTTONLIST',  _buttonlistStore)
  },
  //————————————————模块——————————————
  },
  actions: {
  },
  modules: {
    user,
    system
  }
})
