/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: fxj
 * @Date: 2023-06-12 14:36:40
 * @LastEditors: fxj
 * @LastEditTime: 2023-06-14 14:23:53
 */
import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
// import mixin from '@/plugins/mixin'
import store from '@/store/index'
import vuePrototype from './plugins/vueprototype'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import '@/style/common.css'
import '@/style/icon/iconfont.css'
import util from './utils/util'

//校验登录
router.beforeEach((to, from, next) => {
    ViewUIPlus.LoadingBar.start()
    // 更新
    util.title(to.meta.title)
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
        if (store.state.token) { // 通过vuex state获取当前的token是否存在
            util.openTags({ notChange: to.meta.notChange, name: to.name, path: to.path, title: to.meta.title }, store);
            next();
        } else {
            let _token = localStorage.getItem('SAASTOKEN')
            if (_token) {
                util.openTags({ notChange: to.meta.notChange, name: to.name, path: to.path, title: to.meta.title }, store);
                next();
            } else {
                next({
                    path: '/login'
                })
            }
        }
    } else {
        next()
    }
})


router.afterEach(() => {
    ViewUIPlus.LoadingBar.finish()
    window.scrollTo(0, 0)
})

let _vue = createApp(App)

//绑定原型
Object.keys(vuePrototype).forEach((item,index) =>{
    _vue.config.globalProperties[item] = vuePrototype[item]
 })

 _vue.use(store).use(router).use(ViewUIPlus).mount('#app')
