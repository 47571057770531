/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: fxj
 * @Date: 2021-06-21 15:05:32
 * @LastEditors: fxj
 * @LastEditTime: 2023-06-14 15:19:40
 */
//———————————————————— 用户信息 ————————————————————
export const updateToken = 'updateToken' //更新用户信息

export const emptyToken = 'emptyToken'  //清空用户信息

export const updateRole ="updateRole" //更新用户角色
//——————————————————————————————————————————————



// export const initTag  = 'initTag'
// export const initField = 'initField'
// export const updateSmscode = 'updateSmscode'


//————————————————— 面包屑 ———————————————————————
// 设置当前页面包屑
export const  setCurrentPageName= 'setCurrentPageName'

//面包屑数组更新
export const updateCurrentPath= 'updateCurrentPath'

//———————————————— 浏览记录 ————————————————————

//添加浏览记录  
export const increateTag = 'increateTag'

//清除浏览记录
export const clearAllTags ='clearAllTags'

//清除其余浏览记录
export const clearOtherTags = 'clearOtherTags'

//清除指定浏览记录
export const removeTag = 'removeTag'

//———————————————— 表格头部 ————————————————————————
//表格头部切换
export const increateField = 'increateField'


//———————————————— 主菜单 —————————————————————
//更新按钮列表
export const updateButtonList = "updateButtonList"
//更新当前模块
// export const updateCurrentModules = "updateCurrentModules"
//菜单隐藏/显示
export const toggleMenu = 'toggleMenu'
//打开菜单
export const setOpenSubmenu= 'setOpenSubmenu'
//更新当前菜单选中
export const updateField = 'updateField'
//更新三级菜单
export const updateThirdMenuList = 'updateThirdMenuList'
//清除三级菜单
export const clearThirdMenuList ='clearThirdMenuList'