/*
 * @Author: songtutu 1172120378@qq.com
 * @Date: 2023-07-31 10:41:26
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-30 00:53:36
 * @FilePath: \pre-saas平台开发\src\router\carowner.js
 * @Description: 车主中心
 */
const Layout = () => import('@/components/layout/zy_sass_layout.vue')
const Coupon =() => import('@/views/carowner/zy_sass_coupon.vue')
const CarownerInfo =() => import('@/views/carowner/zy_sass_carownerinfo.vue')
const ChargeOrder =() => import('@/views/carowner/zy_saas_chargeorder.vue')
const RechargeList = () => import('@/views/carowner/zy_saas_rechargelist.vue')
const RefundList = () => import('@/views/carowner/zy_saas_refundlist.vue')
const BlackList = () => import('@/views/carowner/zy_saas_blacklist.vue')
const routes =  { 
  path:'/carowner',
  name:'carowner',
  meta:{
    title:'车主管理',
    requireAuth:true
  },
  component:Layout,
  children:[
    {
      path:'coupon',
      name:'coupon',
      meta:{
        title:'优惠券管理',
        requireAuth:true
      },
      component:Coupon
    },
    {
      path:'carownerinfo',
      name:'carownerinfo',
      meta:{
        title:'车主信息',
        requireAuth:true
      },
      component:CarownerInfo
    },
    {
      path:'chargeorder',
      name:'chargeorder',
      meta:{
        title:'充电订单',
        requireAuth:true
      },
      component:ChargeOrder
    },
    {
      path:'rechargelist',
      name:'rechargelist',
      meta:{
        title:'充值记录',
        requireAuth:true
      },
      component: RechargeList
    },
    {
      path:'refundlist',
      name:'refundlist',
      meta:{
        title:'退款记录',
        requireAuth:true
      },
      component: RefundList
    },
    {
      path:'blacklist',
      name:'blacklist',
      meta:{
        title:'车主黑名单',
        requireAuth:true
      },
      component: BlackList
    }
  ]
  
}

export default routes