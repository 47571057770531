/**
 * http配置
 */

import axios from 'axios'
import store from '@/store'
import router from '@/router'
import  utils  from '@/utils/util'
import  config  from '@/utils/config'
// axios 配置
axios.defaults.timeout = 30000

import {Message} from 'view-ui-plus'

let _http = config.http

/**
 * 处理不同接口地址 
 * @param {*} type 类型
 */
function preOrdev(type ="https://gateway.zhuoyuelvyuan.com"){
  for(let item in _http.olypusHttp){
    axios.defaults[item] = `${type + _http.olypusHttp[item]}`
  }
  for(let item in _http.otherHttp){
    axios.defaults[item] = `${_http.otherHttp[item]}`
  }
}

// 环境的切换
if (process.env.NODE_ENV == 'production') {
  preOrdev("https://gateway.zhuoyuelvyuan.com")
}else if (process.env.NODE_ENV == 'pretest'){
  preOrdev("https://gateway.pre-test.zhuoyuelvyuan.com")
}else if (process.env.NODE_ENV == 'preprod'){
  preOrdev("https://gateway.pre-prod.zhuoyuelvyuan.com")
}else{
  preOrdev("http://gateway.dev.zhuoyuelvyuan.com:30031")
}

axios.defaults.imageurl = "https://minifile.zhuoyuelvyuan.com/"

let timer = null

/**
 * 阻止重复请求
 * @param {array} reqList - 请求缓存列表
 * @param {string} url - 当前请求地址
 * @param {function} cancel - 请求中断函数
 * @param {string} errorMessage - 请求中断时需要显示的错误信息
 */

//全量导出接口url
let allUrl = null 
//调用接口次数
let maxApiNum = 0
//请求数组
let requsetList = []
//响应列表
let responseList = []

/**
 * 请求列表处理
 * @param {Object} reqUrl 请求接口
*/
const getRequest = function(reqUrl){
  if(reqUrl){
    let _url = reqUrl
    let _obj = {
      url:_url.url,
      data:_url.data
    }
    requsetList.push(_obj)
  }
}

/** 
 * 响应列表处理
 * @param {Object} resUrl 响应接口
*/
const getResponse = function(resUrl){

  //返回当前接口是否已经存在
  let _index = requsetList.findIndex((item,index)=>{
    return item.url == resUrl.url
  })
  //当该接口存在，清除该接口
  if(_index != -1){
    requsetList.slice(_index,1)
  }
}



axios.interceptors.request.use(config => { 
  if(config.params && config.params.page%1 > 0) {
    config.params.index = Number((config.params.page%1*100000).toFixed(0))
    let _len = Number((config.params.rows%1*100000).toFixed(0))
    maxApiNum = _len
    config.params.rows =  parseInt(config.params.rows)
    config.params.page =  parseInt(config.params.page)
    allUrl = config.url
    requsetList.length == 0 ? requsetList = new Array(_len).fill(false) : null
  }
    config.headers['Application_Type'] = '2'

    let {token,parentId} = utils.getTokenAndParnterId()
    config.headers['X-Token'] = token
    config.headers['Partner_Id'] = parentId
    
    
    if(config.params !== undefined && config.params.streamTag !== undefined){
      config.responseType ='blob'
    } 
    
    return config

  },
  function (error) {
    return Promise.reject(error)
  }
)
let resNum = 0
axios.interceptors.response.use(function (response) {
  let _index = response.config.params?.index 
  if(response.config.params && _index) {
    resNum++
    if(response.data.errorCode == 1)  {
      responseList[_index-1] = response.data?.data || []
      requsetList[_index-1] = true
    }
    
    if(resNum == maxApiNum && requsetList.filter(item => {return item == true}).length == maxApiNum) {
      response.data.data = responseList.flat()
      response.data.data[0].isAll = true
      resNum = 0
      responseList = []
      requsetList = []
      return response.data
    } else if (resNum == maxApiNum && requsetList.filter(item => {return item == true}).length != maxApiNum) {
      Message.warning({
        content: "全量导出失败，请重试！",
        duration: 10,
        closable: true
      })
      resNum = 0
      responseList = []
      requsetList = []
      return {
        errorCode: 1,
        data: [{isError: true}]
      }
    }
    return {
      errorCode: 10003
    }
  }
  // requsetList.forEach((item, index) => {
  //   if(item && response.config.params && item.url == response.config.url && (item.index) == response.config.params.index) {
  //     // item.data = JSON.parse(JSON.stringify(response.data.data))
  //     item.isOver = true
  //   }
  //   if(!item) {
  //     item.isOver = false
  //   }
  //   !item.isOver ? _isAllOver = false : ''
  //   response.data.data[0].isAll = true
  // })
  // if(requsetList.length > 0 && _isAllOver) {
  //   // let _data = []
  //   // requsetList.forEach((item, index) => {
  //   //   item.data ? _data.push(...item.data) : ''
  //   // })
  //   // response.data.data = _data
  //   requsetList = []
  // }
  
  let _config = response.config

  if((_config.data && typeof  _config.data != 'object' &&  _config.data.includes('streamTag')) || (_config.params && _config.params.streamTag) ){
    return response.data
  }else{

    let code =  response.data.errorCode || response.data.errorCode == 0  ? response.data.errorCode : response.data.data.code; 
    if (code === 10003 ) {
      Message.warning({
        content: '暂无权限，请联系管理员~（'+_config.url+"）",
        duration: 10,
        closable: true
      })
    }


    // token 已过期，重定向到登录页面
    if (code === 4) {
      store.commit('emptyToken')
      router.replace({
        path: '/login'
      })
    }
    
    return response.data
  }
  
}, (error ) => {
  if (!timer) {
    timer = setTimeout(()=> {
      Message.destroy()
      !store.state.ignoreTag ? Message.error("请求超时，请稍后重试...") : ''
      timer = null
    }, 1500)
  }
  return Promise.reject(error)
})

export default axios
