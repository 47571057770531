<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: fxj
 * @Date: 2023-06-12 14:36:40
 * @LastEditors: fxj
 * @LastEditTime: 2023-06-12 16:52:35
-->
<template>
  <router-view/>
</template>

<style lang="scss">

</style>
