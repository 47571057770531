/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: fxj
 * @Date: 2021-06-21 15:05:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-30 23:15:38
 */
import axios from '@/utils/http'

const commonURL = axios.defaults.commonURL;
const chargeURL = axios.defaults.chargeURL;
//字典
export const zy_admin_getOlympusSelectDict = (data) => axios.get(commonURL+'/global/dict/list',{params:data})
//电站下拉
export const zy_admin_getPullStation = (data) => axios.get(chargeURL+'/common/pullStationName',{params:data})
//占桩费模板下拉
export const zy_admin_getPullPileOccupyTemp = (data) => axios.get(chargeURL+'/common/pullPileOccupyTemplateName',{params:data})
//道闸下拉
export const zy_admin_getPullRoadGate = (data) => axios.get(chargeURL+'/common/pullRoadGateName',{params:data})
//软件版本号下拉
export const zy_admin_getPullVersionName= (data) => axios.get(chargeURL+'/common/pullSoftwareVersionName',{params:data})
//上报版本号下拉
export const zy_admin_getPullPileVersionName= (data) => axios.get(chargeURL+'/common/pullCurrentVersionName',{params:data})
//电费模板下拉
export const zy_admin_getPullchargeFeeName= (data) => axios.get(chargeURL+'/common/pullFeeTemplateName',{params:data})
//电桩下拉
export const zy_admin_getPilePullDown= (data) => axios.get(chargeURL+'/common/pullPileSn',{params:data})
//车主下拉
export const zy_admin_getCarownerPullDown= (data) => axios.get(commonURL+'/saas/select/carowner',{params:data})
//上传获取token
export const zy_admin_before_qiniuUpload_getToken = (data) => axios.get( commonURL + '/olympus/qiNiu/uploadToken',{params:data})
// export const zy_admin_before_qiniuUpload_getToken = (data) => axios.get( commonURL + '/invoice/uploadToken',{params:data})
//获取私有文件下载地址
export const  zy_admin_before_qiniuPrivateDownload = (data)=>axios.get(commonURL +'/olympus/qiNiu/privateDownload',{params:data})