const Layout = () => import('@/components/layout/zy_sass_layout')
const InvoicingRecords = () => import('@/views/invoicing/zy_saas_invoicingrecords.vue')
const InvoicingConsmRecords = () => import('@/views/invoicing/zy_saas_invoicingconsmrecords.vue')
const routes = {
  path: '/invoicing',
  name: 'invoicing',
  meta: {
    title: '现金账户管理',
    requireAuth:true
  },
  component: Layout,
  children: [
    {
      path: 'invoicingrecords',
      name: 'invoicingrecords',
      meta: {
        title: '开票记录',
        requireAuth:true
      },
      component: InvoicingRecords
    },
    {
      path: 'invoicingconsmrecords',
      name: 'invoicingconsmrecords',
      meta: {
        title: '开票消费记录',
        requireAuth:true
      },
      component: InvoicingConsmRecords
    }
  ]
}

export default routes