/*
 * @Descripttion: 原型工具类
 * @version: 1.0
 * @Author: fxj
 * @Date: 2021-06-21 15:05:32
 * @LastEditors: fxj
 * @LastEditTime: 2023-06-15 11:40:29
 */

import store from '@/store/index'
import * as echarts from 'echarts';
import {Message} from 'view-ui-plus'
import { toRaw } from 'vue';

let vuePrototype = {

    $echarts: echarts,
    // 随机生成单号
    generateMixed:(n)=>{
        let date = new Date();
        if (n===0) {
            return date.getFullYear().toString()+(date.getMonth()+1).toString()+date.getDate().toString();
        } else {
            var chars = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];    
             var res = "";
             for(var i = 0; i < n ; i ++) {
                 var id = Math.ceil(Math.random()*35);
                 res += chars[id];
             }
             return res;        
        }
    },
  

    //检测是否在数组内
    isInArray:(arr,val)=>{
        if(arr){
          //权限
          if(typeof arr != 'object'){
            return arr.split(',').includes(val)
          }else{
            //头部切换
            return arr.includes(val)
          }
        }
        
    },

    /** 
     * 按钮权限判断
     * @param {Array} arr 权限列表
    */
    verificationBots:function(arr = []){
       
        //返回权限校验结果(Object)
        let _actions = new Object()
        const _storeButtonlist = toRaw(store.state.buttonlist)
        const _localButtonlist = localStorage.getItem('SAASBUTTONLIST')
        let _buttonlist = _storeButtonlist.length ? _storeButtonlist : _localButtonlist

        if(arr.length > 0){
           arr.forEach((item,index) =>{
            vuePrototype.isInArray(_buttonlist,item.verification) ? _actions[item.name] = true : _actions[item.name] = false
           })
        }

        return  _actions
        
    },

    //图标
    $store:()=>{
       return store
    },

    /** 
     * 重置参数
     * @param {String} param1 需要重置的参数
     * @param {String} param2 重置的参数值
    */
    restParameter:function(param1,param2){
       if(!param2) { param2 = param1 }
       Object.assign(this[param1],this.$options.data()[param2])
    },
    
    //详情权限提示
    isDetailPower:(content="请先获取详情权限！")=>{
       return Message.error({
        content,
        duration: 5,
        closable: true
      });
    },

    //正在加载
    spinShow:(title="正在加载")=>{
        return Message.loading({
            top:500,
            duration:0,
            content: title
        });
    },

    // 验证手机号
    checkMobile: (m) =>{
        let mobileReg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
        return mobileReg.test(m) ? true : false;
    }

}

export default vuePrototype;