/*
 * @Descripttion: 系统
 * @version: 1.0
 * @Author: fxj
 * @Date: 2023-06-12 14:36:40
 * @LastEditors: fxj
 * @LastEditTime: 2023-06-14 11:57:13
 */
import { createStore } from 'vuex'
//菜单隐藏/显示
const toggleMenu = 'toggleMenu'

export default createStore({
  state: {
    buttonlist:[],
    model:{},
    currentPath:0,
    menuPadding: 200
  },
  getters: {
  },
  mutations: {
    /**
     * 控制菜单显示/隐藏
     * 当为treu时，打开菜单，否则关闭菜单
     * @param {Object} state 
     * @param {Boolean} flag  //主菜单显示/隐藏标志
     */
    [toggleMenu](state, flag) {
      flag ? state.menuPadding = 200 : state.menuPadding = 0
    }
  },
  actions: {
  }
})
