let CryptoJS = require('crypto-js')
const  tripledes = require("crypto-js/tripledes");

//加密规则
class CryptoJSUtil {

    constructor(signStr){
      this.signStr = signStr
    }

    //生成盐值
    CryptoJSKey(){
        return CryptoJS.enc.Utf8.parse('zhuoyue');
    };

    //生成加密MD5
    CryptoJSMD5(){
        return CryptoJS.MD5('zhuoyue'+ this.signStr).toString().toUpperCase()
    }
    
    //解密方法
    CryptoJSDecrypt() {
        const plaintext = CryptoJS.DES.decrypt(this.signStr, this.CryptoJSKey(), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return plaintext.toString(CryptoJS.enc.Utf8)
    }
    
    //加密方法
    CryptoJSEncrypt() {
        const keyHex = this.CryptoJSKey();
        const encrypted = CryptoJS.DES.encrypt(this.signStr, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString()
        // return tripledes.encrypt(this.signStr, this.CryptoJSIv()).toString();
    }

    /*Base64加密*/
    setAES(KEY = "zhuoyue") {
        let wordArray = CryptoJS.enc.Utf8.parse(this.signStr);
		let strBase64 = CryptoJS.enc.Base64.stringify(wordArray);
        return strBase64
    }
    /*Base64解密*/
    decryptAES(KEY = "zhuoyue") {
        let parsedWordArray = CryptoJS.enc.Base64.parse(this.signStr);
		let parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
        return parsedStr
    }

    //入口
    static entrance(signStr){
       return  new CryptoJSUtil(signStr)
    }
}

export default CryptoJSUtil.entrance