/*
 * @Descripttion: 系统常量
 * @version: 1.0
 * @Author: fxj
 * @Date: 2021-11-29 15:38:25
 * @LastEditors: fxj
 * @LastEditTime: 2022-08-18 13:10:54
 */
const config ={
  http:{
    olypusHttp:{
      zhuoyuelvyuanURL : '',
      baseURL : '',
      systemURL : '/business-system/web-olympus-center',
      chargeURL: '/saas-charge/web-saas-center',
      commonURL: '/saas-common/web-saas-center'
    },
    otherHttp:{
      clodURL :'https://cloudchargeapi.zhuoyuecar.com/wechat/',
      boadURL :'https://a.zhuoyuecar.com/'
    }
  }
}

export default config