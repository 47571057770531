import html2canvas from 'html2canvas';
import CryptoJSUtil from '@/utils/crypto'
import download from '@/utils/download'
import * as qiniu from 'qiniu-js'
import AreaJs from "@/plugins/area";
import { Message } from 'view-ui-plus'
import { getCurrentInstance } from 'vue'
import store from '@/store/index'
import router from '@/router'
// import {
//   zy_admin_getOlympusSelectDict,
//   zy_admin_before_qiniuPrivateDownload,
//   zy_admin_before_qiniuUpload_getToken
// } from '@/api/pub'
import { 
  zy_admin_before_qiniuUpload_getToken
} from '@/api/pub'
import assert from 'assert'
import {
  zy_admin_getOlympusSelectDict
} from '@/api/pub'

let util = {};

// 根据字典生成随机序列
util.randomCode = function (len, dict) {
  let rs = ''
  for (let i = 0; i < len; i++)
    rs += dict.charAt(Math.floor(Math.random() * 100000000) % dict.length);
  return rs;
};

// 生成随机手机号码
util.randomPhoneNumber = function () {
  // 第1位是1 第2,3位是3458 第4-7位是* 最后四位随机
  return [1, util.randomCode(2, '3458'), '****', util.randomCode(4, '0123456789')].join('');
};

/**
 * 本地存储加密
 * @param {String} localStorageKey 加密key
 * @param {String} localStorageValue 加密值
 */
util.CryptoJSEncrypt = function (localStorageKey, localStorageValue) {
  let _value = CryptoJSUtil(localStorageValue).CryptoJSEncrypt();
  localStorage.setItem(localStorageKey, _value)
}

/**
 * 本地存储解密
 * @param {String} localStorageKey 解密key
 */
util.CryptoJSDecrypt = function (localStorageKey) {
  let _value = localStorage.getItem(localStorageKey)
  if (_value) {
    return CryptoJSUtil(_value).CryptoJSDecrypt();
  }
}
/**
 * 删除本地加密存储
 * @param {String} localStorageKey 解密key
 */
util.CryptoJSRemove = function (localStorageKey) {
  localStorage.removeItem(localStorageKey)
}

util.getDistance = function (lat1, lng1, lat2, lng2) {
  let radLat1 = util.rad(lat1);
  let radLat2 = util.rad(lat2);
  let a = radLat1 - radLat2;
  let b = util.rad(lng1) - util.rad(lng2);
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137;// EARTH_RADIUS; 
  s = Math.round(s * 10000) / 10000; //输出为公里 
  //s=s.toFixed(4); 
  return s;
},
  /**
   * @name: 
   * @test: test font
   * @msg: 
   * @param {*} list 目标数组
   * @param {*} key 数组里面的key名
   * @param {*} value key要对应的值
   * @return {*}
   */
  util.getItemInDic = function (list, key, value) {
    for (let item of list) {
      if (item[key] == value) {
        return item
      }
    }
    return {}
  }

//设置标题
util.title = function (title) {
  title = title ? title : '卓越后台管理';
  window.document.title = title;
};


const carlist = ['京', '湘', '津', '鄂', '沪', '粤', '渝', '琼', '翼', '川', '晋', '贵', '辽', '云', '吉', '陕', '黑', '甘', '苏', '青', '浙', '皖', '藏', '闽', '蒙', '赣', '桂', '鲁', '宁', '豫', '新']
const allKeyWord = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '港', '澳', '学', '领', '警']

//校验车牌
util.isCarNumber = function (carNumber) {
  let tag = true
  let number = carNumber ? carNumber.toUpperCase() : "";
  let carNumberList = number.split("");
  if (carNumberList.length < 7 || carNumberList.length > 8) { return tag = false }
  if (carlist.indexOf(carNumberList[0]) == -1) { return tag = false }
  carNumberList.slice(1, carNumberList.length).forEach((item, index) => {
    if (allKeyWord.indexOf(item) == -1) { tag = false }
  })
  return tag
}


// 参数说明：当前实例,状态库
util.openTags = function (vm, store) {
  let { name, path, title } = vm
  let tagList = store.state.tagList; //路由地址记录
  let tagListLen = tagList.length;
  let i = 0;
  let tagHasOpened = false;
  while (i < tagListLen) {
    if (name === tagList[i].name) {
      // 页面已经打开
      tagHasOpened = true;
      break;
    }
    i++;
  }
  if (name != "error") {
    if (!tagHasOpened) {
      // 添加路由记录
      store.commit('increateTag', {
        title,
        path,
        name
      });
    }

    //设置当前页名称
    store.commit('setCurrentPageName', name);
    if (name) {

      const _storeMenuList = store.state.thirdMenuList
      const _localMenuList = JSON.parse(localStorage.getItem('SAASTHIRDMENULIST'))
      let _isStoreList = Object.keys(_storeMenuList)
      let _menuList = _isStoreList.length ? _storeMenuList : (_localMenuList ? _localMenuList : [])

      if (Object.keys(_menuList).length) {

        //需要渲染的菜单
        let _pageMenuList = []
        _menuList.forEach(el => {
          if (el.href == name) {
            _pageMenuList = [el]
          }
        })

        _pageMenuList.forEach(item => {
          if (name == item.href) {
            // let _model = store.state.model || JSON.parse(localStorage.getItem("ZYMODALMSG"))
            // const _storeModelList = store.state.model.modelList
            // const _localModelList = JSON.parse(localStorage.getItem("ZYMODALLIST"))
            // let _modelList = _storeModelList.length ? _storeModelList : _localModelList
            // //设置当前模块信息
            // if(_model.id != item.moduleId){
            //   _modelList.forEach(el=>{
            //     if(el.id == item.moduleId ){
            //       // store.commit('updateModel', {id:item.moduleId,name:el.name});
            //     }
            //   })
            // }

            //设置打开菜单
            if (item.isMenu == 1) {
              store.commit('setOpenSubmenu', [name]);
            } else if (item.isMenu == 2) {
              store.commit('setOpenSubmenu', [item.parentId]);
            }
            //设置面包屑
            if (item.parentName) {
              store.commit('updateCurrentPath', [{ name: item.parentName }, { name: title }])
            } else {
              store.commit('updateCurrentPath', [{ name: title }])
            }
          }
        })
      }
    }

  }

};



util.openFields = function (vm) {
  const name = vm.$route.name;
  let fieldArr = vm.tableColumnsChecked;
  let fieldList = vm.$store.state.tagField;
  let fieldListLen = fieldList.length;
  let i = 0;
  let fieldExist = false;
  while (i < fieldListLen) {
    if (name === fieldList[i].name) {
      // 当前页面已经存在
      vm.$store.commit('updateField', {
        index: i,
        field: fieldArr,
        name
      });
      fieldExist = true;
      break;
    }
    i++;
  }
  if (!fieldExist) {
    vm.$store.commit('increateField', {
      field: fieldArr,
      name
    });
  }
};

util.getFields = function (vm) {
  var name = vm.$route.name;
  let fieldList = vm.$store.state.tagField;
  let fieldListLen = fieldList.length;
  let i = 0;
  while (i < fieldListLen) {
    if (name === fieldList[i].name) {
      return fieldList[i].field;
      break;
    }
    i++;
  }
  return [];
};

//表格切换expand时候更改css样式
util.setExpand = function (status) {
  let el = document.getElementsByClassName('ivu-table-fixed');
  let elright = document.getElementsByClassName('ivu-table-fixed-right');
  if (status) {
    el[0].style.boxShadow = elright[0].style.boxShadow = 'none';
  } else {
    el[0].style.boxShadow = '2px 0 6px -2px rgba(0,0,0,.2)';
    elright[0].style.boxShadow = '-2px 0 6px -2px rgba(0,0,0,.2)';
  }
};
util.formatSeconds = (value) => {
  var theTime = parseInt(value);// 需要转换的时间秒 
  var theTime1 = 0;// 分 
  var theTime2 = 0;// 小时 
  var theTime3 = 0;// 天
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
      if (theTime2 > 24) {
        //大于24小时
        theTime3 = parseInt(theTime2 / 24);
        theTime2 = parseInt(theTime2 % 24);
      }
    }
  }
  var result = '';
  if (theTime > 0) {
    result = "" + parseInt(theTime) + "秒";
  }
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  if (theTime3 > 0) {
    result = "" + parseInt(theTime3) + "天" + result;
  }
  return result;
},
  // 打印
  util.printDocument = function (el) {
    let newWindow = window.open('_blank');
    let codestr = document.getElementById(el).innerHTML;
    newWindow.document.write(codestr);
    newWindow.document.close();
    newWindow.print();
    newWindow.close();
    return true;
  };

// 导出图片
util.exportImage = function (vm, el) {
  let table = document.getElementById(el);
  html2canvas(table).then(canvas => {
    var url = canvas.toDataURL();
    let a = document.createElement('a');
    a.href = url;
    a.download = `${vm.$route.meta.title}${vm.generateMixed(0)}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
};


/*获取上个月
 *格式为yyyy-mm-dd的日期，如：2014-01-25
*/
util.getPreMonth = (date) => {
  var arr = date.split('-');
  var year = arr[0]; //获取当前日期的年份
  var month = arr[1]; //获取当前日期的月份
  var day = arr[2]; //获取当前日期的日
  var days = new Date(year, month, 0);
  days = days.getDate(); //获取当前日期中月的天数
  var year2 = year;
  var month2 = parseInt(month) - 1;
  if (month2 == 0) {
    year2 = parseInt(year2) - 1;
    month2 = 12;
  }
  var day2 = day;
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  var t2 = year2 + '-' + month2;
  return t2;
}
/*获取下个月
 * 格式为yyyy-mm-dd的日期，如：2014-01-25
*/
util.getNextMonth = (date) => {
  var arr = date.split('-');
  var year = arr[0]; //获取当前日期的年份
  var month = arr[1]; //获取当前日期的月份
  var day = arr[2]; //获取当前日期的日
  var days = new Date(year, month, 0);
  days = days.getDate(); //获取当前日期中的月的天数
  var year2 = year;
  var month2 = parseInt(month) + 1;
  if (month2 == 13) {
    year2 = parseInt(year2) + 1;
    month2 = 1;
  }
  var day2 = day;
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }

  var t2 = year2 + '-' + month2;
  return t2;
}

util.formatDate = function (type, time) {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  if (month <= 9) {
    month = '0' + month
  }
  if (day <= 9) {
    day = '0' + day
  }
  if (hour <= 9) {
    hour = '0' + hour
  }
  if (minute <= 9) {
    minute = '0' + minute
  }
  if (second <= 9) {
    second = '0' + second
  }
  if (type === 1) {
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  } else if (type === 2) {
    return year + '-' + month + '-' + day;
  } else if (type === 3) {
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  } else if (type === 4) {
    return year + '-' + month;
  } else if (type === 5) {
    return `${year}${month}${day}`;
  } else if (type == 6) {
    return `[${hour},${minute},${second}]`;
  } else if (type == 7) {
    return hour + ':' + minute + ':' + second;
  } else if (type == 8) {
    return `${year}${month}${day}${hour}${minute}${second}`;
  }
};


/*获取某个时间点的 几天前
 * num 天数
 * type 返回时间格式
*/
util.getDaysAgo = (num = 0, time = new Date(), type = 2) => {
  let _now = new Date(time).getTime()
  let _time = _now - num * 24 * 60 * 60 * 1000
  return util.formatDate(type, _time)

}

//两个时间相差天数 兼容firefox chrome
util.datedifference = (sDate1, sDate2) => {    //sDate1和sDate2是2006-12-18格式 
  var dateSpan, tempDate, iDays;
  sDate1 = Date.parse(sDate1);
  sDate2 = Date.parse(sDate2);
  dateSpan = sDate2 - sDate1;
  dateSpan = Math.abs(dateSpan);
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
  return iDays
};

util.nextMonth = (date) => {
  var arr = date.split('-');
  var year = arr[0]; //获取当前日期的年份
  var month = arr[1]; //获取当前日期的月份
  var day = arr[2]; //获取当前日期的日
  var days = new Date(year, month, 0);
  days = days.getDate(); //获取当前日期中的月的天数
  var year2 = year;
  var month2 = parseInt(month) + 1;
  if (month2 == 13) {
    year2 = parseInt(year2) + 1;
    month2 = 1;
  }
  var day2 = day;
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }

  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
},

  //下一天
  util.getNextDay = (d) => {
    d = new Date(d);
    d = +d + 1000 * 60 * 60 * 24;
    d = new Date(d);

    var yy = d.getFullYear();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var nextday = yy + "-";
    if (mm < 10) { nextday += "0" }
    nextday += mm + "-";
    if (dd < 10) { nextday += "0" }
    nextday += dd;
    return nextday;

  }



util.formatTimestamp = function (date) {
  var date = new Date(date);
  return date.getTime();
};
// 获取时间戳
util.getTime = function (Time) {
  return new Date(Time).getTime()
};
//获取某月天数
util.getDaysInOneMonth = function (time) {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = parseInt(month, 10);
  var d = new Date(year, month, 0);
  return d.getDate();
}

/*获取当前年的第几周，以及周对应的日期范围（根据当前日期的时间）
*@author weiyongfu
*@date 2017-11-17
*/
util.getYearWeekRange = function (year, weeks, weekDay) {
  //用指定的年构造一个日期对象，并将日期设置成这个年的1月1日
  //因为计算机中的月份是从0开始的,所以有如下的构造方法
  var date = new Date(year, "0", "1");

  //取得这个日期对象 date 的长整形时间 time
  var time = date.getTime();

  //将这个长整形时间加上第N周的时间偏移
  //因为第一周就是当前周,所以有:weeks-1,以此类推
  //7*24*3600000 是一星期的时间毫秒数,(JS中的日期精确到毫秒)
  time += (weeks - 1) * 7 * 24 * 3600000;

  //为日期对象 date 重新设置成时间 time
  date.setTime(time);
  weekDay %= 7;
  var day = date.getDay();
  var time = date.getTime();
  var sub = weekDay - day;
  time += sub * 24 * 3600000;
  date.setTime(time);
  return date;
}


//过滤空键值对
util.filterObject = (data = {}) => {
  let _data = data
  if (Object.prototype.toString.call(_data) == '[object Object]') {
    for (let key in _data) {
      if ((!_data[key] && _data[key] != 0) || _data[key] == "") {
        delete _data[key]
      }
    }
  }
  return _data
}


//下载文件
util.downloadFile = function (file, name) {
  // let key = file.slice(file.lastIndexOf("/")+1,file.length)
  // zy_admin_before_qiniuPrivateDownload({
  //   key,
  //   bucketName:'zystation'
  // }).then(res=>{
  //   if(res.errorCode == 1){
  //     let _file = res.data
  //     let _num = _file.slice(_file.indexOf("%")+1,_file.indexOf("?"))
  //     let type = _num.slice(_num.indexOf("."),_num.length)
  //     let name = _file.slice(_file.indexOf("/")+1,_file.indexOf("%"))
  //     download(_file, type, "text/plain");
  //   }

  // }).catch()
}
//省市区解析
util.formateCode = function (provinceCode, cityCode, districtCode) {
  let areaName;
  const _area = AreaJs.region;
  _area.forEach((item) => {
    if (provinceCode == item.provinceCode) {
      areaName = item.provinceName + '/'
      if (item.mallCityList) {
        item.mallCityList.forEach((el) => {
          if (el.cityCode == cityCode) {
            areaName = areaName + el.cityName + '/'
            if (el.mallAreaList) {
              el.mallAreaList.forEach((m) => {
                if (m.districtCode == districtCode) {
                  areaName = areaName + m.areaName
                }
              });
            }
          }
        });
      }
    }
  })
  return areaName
}

//七牛上传
function qiniuUpload(file, success, qiniuParams) {
  let { key, token, agreement } = qiniuParams
  Message.info({
    content: '上传中...',
    duration: 0,
    closable: true
  });
  let observer = {
    next(res) { },
    error(err) { },
    complete(res) {
      // Message.destroy()
      Message.success({
        content: '上传成功！',
        duration: 10,
        closable: true
      });
      return success((agreement ? agreement : 'http') + '://' + res.url)
    }
  }
  let observable = qiniu.upload(file, key, token, {}, {})
  observable.subscribe(observer) // 上传开始
}

//七牛上传图片
util.qiniuUpload = function (uploadParams = { suffix, originName, bucketName: 'minifile', agreement: 'http' }, file, callback) {
  let _uploadParams = Object.assign({ bucketName: 'minifile' }, uploadParams)
  zy_admin_before_qiniuUpload_getToken({
    ..._uploadParams,
  }).then(res => {
    if (res.errorCode == 1) {
      qiniuUpload(file,callback,{key:res.data.key,token:res.data.token,agreement:_uploadParams.agreement})
    } else {
      Message.error(res.message)
    }
  }).catch()
}

//前端下载图片
util.downLoadImage = function (img, name = "图片") {
  let type = img.slice(img.lastIndexOf("."), img.length)
  download(img, type, "text/plain");
}



/**
 * 批量更改表单是否需校验
 * @param {Array} rlues 需要更改的项
 * @param {Boolean} type 赋值
 * @param {String} form 需要更改的表单(ref)
 */
util.changeRules = function (vm, rlues = [], type = false, form = 'modalForm') {
  assert(Object.prototype.toString.call(rlues) == '[object Array]','更改的项必须是数组类型！')
  assert(Object.prototype.toString.call(type) == '[object Boolean]','赋值必须是布尔类型！')
  assert(Object.prototype.toString.call(form) == '[object String]','改的表单必须是字符串类型！')
  // let { proxy } = getCurrentInstance()
  if (rlues.length) {
    rlues.forEach(item => {
      vm.$refs[form].rules[item][0].required = type

    })
  }
}

//批量获取字典
util.getDictList = function (codeList = []) {
  return new Promise((resolve, reject) => {
    assert(Array.isArray(codeList),'codeList必须是数组类型！')
    let { proxy } = getCurrentInstance()
    const _promiseList = []
    codeList.forEach(item => {
      zy_admin_getOlympusSelectDict({
        code: item
      }).then(res => {
        if (res.errorCode === 1) {
          proxy.dictList[`_${item}`] = res.data
        } else {
          proxy.dictList[`_${item}`] = []
        }
        resolve()
      }).catch()
    })
    Promise.all(_promiseList).then(() => {
      resolve()
    }).catch(() => reject())
  })

}

//富文本器文本
util.dealTextArea = function (val) {
  let _val = val;
  if (_val) {
    return _val.slice(_val.indexOf('<body>') + 7, _val.indexOf('</body>'))
  } else {
    return null
  }
}

/**
 * 批量转化状态值
 * @param {Array} primitives 原始值
 * @param {Array} statusList 转换列表[{key:"转化字段",value:"转化列表"}]
 */
util.formateStatus = function (primitives = [], statusList = []) {
  assert(Array.isArray(primitives),'原始值必须是数组类型！')
  statusList.forEach(item => {
    if (!item.hasOwnProperty('key') || !item.hasOwnProperty('value')) {
      assert(Array.isArray(primitives),'转换列表必须是{key,value}形式数组类型！')
    }
  })
  assert(Array.isArray(statusList),'转换列表必须是数组类型！')
  primitives.forEach(item => {
    statusList.forEach(el => {
      el.value.forEach(i => {
        if (i.dictKey == item[el.key]) {
          item[`_${el.key}`] = i.dictValue
          item[`_${el.key}DataStructure`] = i.dataStructure
        }
      })
    })
  })

  return primitives
}



//获取token与加盟商id
util.getTokenAndParnterId = function () {
  let token
  if (store.state.token) {
    token = `${store.state.userinfo.id}|${store.state.token}`
  } else {
    let _token = util.CryptoJSDecrypt('SAASTOKEN')
    token = _token ? _token : null
  }
  let parentId
  if (store.state.user.parentId) {
    parentId = `${store.state.user.parentId}`
  } else {
    let _parentId = util.CryptoJSDecrypt('SAASPARTNERID')
    parentId = _parentId ? _parentId : null
  }
  return { token, parentId }
}

//下载二进制流文件
util.downloadSteam = function (file, name) {
  const blob = file;
  let link = document.createElement("a");
  link.href = URL.createObjectURL(new Blob([blob], { type: "application/vnd.ms-excel;charset=utf-8" }));
  link.download = name;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
}

//生成uniapp二维码 加密
util.createUniQrCode = function (date = "-1", params = [], dictKey = "1", pre = 'https://c.zhuoyuecar.com/h5/#/qr', type = "u") {
  let _str = date + '|'
  params.forEach(item => {
    _str += (item + '|')
  })
  _str = type + '/' + dictKey + '/' + _str
  return pre + '/' + CryptoJSUtil(_str).setAES()
}


util.getCalculationTime = function (startTime, endTime) {
  let s1 = new Date(startTime),
    s2 = new Date(endTime),
    runTime = parseInt((s2.getTime() - s1.getTime()) / 1000);
  let year = Math.floor(runTime / 86400 / 365);
  runTime = runTime % (86400 * 365);
  let month = Math.floor(runTime / 86400 / 30);
  runTime = runTime % (86400 * 30);
  let day = Math.floor(runTime / 86400);
  runTime = runTime % 86400;
  let hour = Math.floor(runTime / 3600);
  runTime = runTime % 3600;
  let minute = Math.floor(runTime / 60);
  runTime = runTime % 60;
  let second = runTime;
  return { year, month, day, hour, minute, second }
}
/**
 * @name: 
 * @test: test font
 * @msg: 判断是否有重复时间段
 * @param {*} data
 * @return {*}
 */
util.isHasRepeatTime = (data) => {
  const startTimeArr = []
  const endTimeArr = [];
  (data || []).map(function (item) {
    startTimeArr.push(item.startTime)
    endTimeArr.push(item.endTime)
  })
  const allStartTime = startTimeArr.sort()
  const allEndTime = endTimeArr.sort()
  let result = 0
  for (let k = 1; k < allStartTime.length; k++) {
    if (allStartTime[k] < allEndTime[k - 1]) {
      result += 1
    }
  }
  return result > 0
}
/**
 * @name: 
 * @test: test font
 * @msg: 判断时间段是否满一天
 * @param {*} data
 * @return {*}
 */
util.isAllDay = (data) => {
  let time = 0;
  (data || []).map(item => {
    let startMin = Number(item[0].split(":")[0] * 60) + Number(item[0].split(":")[1])
    let endMin = Number(item[1].split(":")[0] * 60) + Number(item[1].split(":")[1])
    time = time + (endMin - startMin)
  })
  if (time < (23 * 60 + 59)) {
    return false
  }
  return true
}


/**
 * 小数运算的加减乘除
 * @param {String} front 算术符号前边小数
 * @param {String} behind 算术符号后边小数
 */
util.getOperationResult = function () {
  class OperationFun {
    //除法 
    accDiv(front, behind) {
      let t1 = 0, t2 = 0, r1, r2;
      try { t1 = front.toString().split(".")[1].length } catch (e) { }
      try { t2 = behind.toString().split(".")[1].length } catch (e) { }
      r1 = Number(front.toString().replace(".", ""))
      r2 = Number(behind.toString().replace(".", ""))
      return this.accMul((r1 / r2), Math.pow(10, t2 - t1));
    }

    //乘法 
    accMul(front, behind) {
      let m = 0, s1 = front.toString(), s2 = behind.toString();
      try { m += s1.split(".")[1].length } catch (e) { }
      try { m += s2.split(".")[1].length } catch (e) { }
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }

    //加法 
    accAdd(front, behind) {
      let r1, r2, m;
      try { r1 = front.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = behind.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2))
      return (front * m + behind * m) / m
    }
    //减法 
    accSubtr(front, behind) {
      let r1, r2, m, n;
      try { r1 = front.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = behind.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2));
      n = (r1 >= r2) ? r1 : r2;
      return ((front * m - behind * m) / m).toFixed(n);
    }
  }

  const operationFun = new OperationFun()
  return operationFun
}

export default util;