const Layout = () => import('@/components/layout/zy_sass_layout')
const AdjustAccount = () => import('@/views/cashaccount/zy_saas_adjustaccount.vue')
const ObtainRecords = () => import('@/views/cashaccount/zy_saas_obtainrecords.vue')
const ConsumptionRecords = () => import('@/views/cashaccount/zy_saas_consumptionrecords.vue')
const routes = {
  path: '/cashaccount',
  name: 'cashaccount',
  meta: {
    title: '现金账户管理',
    requireAuth:true
  },
  component: Layout,
  children: [
    {
      path: 'adjustaccount',
      name: 'adjustaccount',
      meta: {
        title: '现金账户调整',
        requireAuth:true
      },
      component: AdjustAccount
    },
    {
      path: 'obtainrecords',
      name: 'obtainrecords',
      meta: {
        title: '获取记录',
        requireAuth:true
      },
      component: ObtainRecords
    },
    {
      path: 'consumptionrecords',
      name: 'consumptionrecords',
      meta: {
        title: '消费记录',
        requireAuth:true
      },
      component: ConsumptionRecords
    }
  ]
}

export default routes