/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: fxj
 * @Date: 2023-06-14 13:38:14
 * @LastEditors: fxj
 * @LastEditTime: 2023-06-14 14:13:36
 */
//帐户模块
const Layout = () => import('@/components/layout/zy_sass_layout.vue')
const Station =() => import('@/views/station/zy_sass_station.vue')
const PileInfo =() => import('@/views/station/zy_saas_pileinfo.vue')
const GunInfo =() => import('@/views/station/zy_saas_guninfo.vue')
const Soc =() => import('@/views/station/zy_saas_soc.vue')
const Occupy =() => import('@/views/station/zy_saas_occupyfeetemp.vue')
const routes =  { 
  path:'/station',
  name:'station',
  meta:{
    title:'电站信息',
    requireAuth:true
  },
  component:Layout,
  children:[
    {
      path:'stationinfo',
      name:'stationinfo',
      meta:{
        title:'电站信息',
        requireAuth:true
      },
      component:Station
    },
    {
      path:'pileinfo',
      name:'pileinfo',
      meta:{
        title:'电桩信息',
        requireAuth:true
      },
      component:PileInfo
    },
    {
      path:'guninfo',
      name:'guninfo',
      meta:{
        title:'电枪信息',
        requireAuth:true
      },
      component:GunInfo
    },
    {
      path:'soctemp',
      name:'soctemp',
      meta:{
        title:'充电SOC上限模板',
        requireAuth:true
      },
      component:Soc
    },
    {
      path:'occupytemp',
      name:'occupytemp',
      meta:{
        title:'占桩费模板',
        requireAuth:true
      },
      component:Occupy
    }
  ]
  
}

export default routes