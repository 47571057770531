/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: fxj
 * @Date: 2023-06-12 14:36:40
 * @LastEditors: songtutu 1172120378@qq.com
 * @LastEditTime: 2023-07-12 10:06:05
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import packageList from '../plugins/packageList'
import store from '@/store/index'

const Login =  () => import('@/views/zy_sass_login.vue')  //登录
const Home =  () => import('@/views/home/zy_saas_home.vue')  //首页
// const Dashboard =  () => import('@/views/zy_sass_dashboard.vue')  //主菜单
const Layout =  () => import('@/components/layout/zy_sass_layout')  //首页
const ErrorPage = () => import('@/views/error/zy_sass_error.vue') //错误页面

//其余模块加载
let asyncRouterMap = ()=>{
 
  let _asyncRouteList = [],_arr =[]
  packageList.forEach(item=>{
    _asyncRouteList.push(require("@/router/"+item).default)
  })
  _asyncRouteList.forEach((item) =>{
    if(Object.keys(item).length){
      _arr.push(item)
    }
  })
  return _arr
}

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: Login
  },
  {
    path: '/',
    redirect:'login'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页',
      requireAuth:true
    },
    component: Layout,
    children:[
      {
        path: '',
        name: 'home1',
        meta: {
          title: '首页',
          requireAuth:true
        },
        component: Home,
      }
    ]
  },
  ...asyncRouterMap(),
  {
    path: '/:catchAll(.*)',
    name: 'error',
    meta: {
      title: '404页面',
      requireAuth: true
    },
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
