/*
 * @Descripttion: 用户
 * @version: 1.0
 * @Author: fxj
 * @Date: 2023-06-12 14:36:40
 * @LastEditors: fxj
 * @LastEditTime: 2023-06-13 14:45:23
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    userinfo:{}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
