/*
 * @Descripttion: 加载路由
 * @version: 1.0
 * @Author: fxj
 * @Date: 2021-06-21 15:05:32
 * @LastEditors: fxj
 * @LastEditTime: 2021-11-27 14:43:50
 */
let _tempPackageList = require.context('../router', true, /.js$/).keys();
let packageList = []
_tempPackageList.forEach((item,index) =>{
    if(!item.includes("index")){
        packageList[index] = item.replace(/\./,'').replace(/\//,'')
    }
})
export default  packageList;